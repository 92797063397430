import { Box, Button, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useState } from "react";
import { searchAll, searchCompanies } from "../../data/request";
// import { SearchResult } from "../../types/SearchResult";
import { useNavigate, useParams } from "react-router-dom";
import { DELTA_PRIMARY } from "../colors";
import { BORDER_RADIUS } from "../dimens";

export const SearchBox = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const onClick = async () => {
    if (searchText == null || searchText === "") {
      alert("Search field cannot be empty!");
      return;
    }
    const newPath = `/search/${searchText}`;
    navigate(newPath);
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onClick();
    }
  };

  const onSearchTextChanged = async (value: string) => {
    setSearchText(value);
  };

  const searchBox = (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{
        padding: 20,
        display: "flex",
        background: DELTA_PRIMARY,
        borderTopRightRadius: BORDER_RADIUS,
        borderTopLeftRadius: BORDER_RADIUS,
      }}
    >
      <TextField
        id="outlined-basic"
        label="Search Company or Officer"
        variant="filled"
        style={{ flex: 1, background: "white" }}
        onKeyDown={handleKeyDown}
        onChange={(event) => {
          onSearchTextChanged(event.target.value);
        }}
      />
      <Button
        variant="contained"
        onClick={onClick}
        style={{ marginLeft: 20, background: DELTA_PRIMARY }}
      >
        Search
      </Button>
    </Box>
  );

  return searchBox;
};

export default SearchBox;
