import React from "react";
import "./News.css"; // Import external stylesheet
import { DELTA_PRIMARY } from "../colors";

const News = () => {
  const newsLink =
    "https://news.sky.com/story/autumn-statement-2023-see-if-you-are-better-or-worse-off-after-chancellor-jeremy-hunts-plans-13013709";
  return (
    <div style={{ padding: 20 }}>
      <h2>Latest News</h2>
      <p>
        <a
          href={newsLink}
          target="_blank"
          rel="noopener noreferrer"
          className="readable-link"
          style={{ color: DELTA_PRIMARY }}
        >
          {
            "Autumn Statement 2023: See if you are better or worse off after Chancellor Jeremy Hunt's plans"
          }
        </a>
      </p>
    </div>
  );
};

export default News;
