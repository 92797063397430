import List from "@mui/material/List";
import { AppointmentItem } from "./AppointmentItem";

export const AppointmentList = (props: { data: any[] }) => (
  <List>
    {props.data.map((value) => (
      <AppointmentItem data={value} />
    ))}
  </List>
);
