export {
  getValueDisplay,
  getAddressDispay,
  getDateDisplay,
  safeIndex,
  pdfName,
  log,
};

// ------------------------------------------------------
// Macros
// ------------------------------------------------------
const UNKNOWN = "--";

const __DEV__ = true;

export const EMUM_EXCEPTION_WORDS = ["to", "of"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// ------------------------------------------------------
// Exported Functions
// ------------------------------------------------------

function log(s: any): any {
  if (__DEV__) {
    console.log(s);
  }
}

function safeIndex(json: any, index: any): any {
  if (typeof index == "string") {
    return safeIndexInner(json, [index]);
  } else {
    return safeIndexInner(json, index);
  }
}

function safeIndexInner(json: any, index: any): any {
  if (index.length == 0 || typeof json == "undefined") {
    return json;
  } else {
    return safeIndexInner(json[index[0]], index.slice(1, index.length));
  }
}

// ------------------------------------------------------
function getValueDisplay(data: any): any {
  if (data == undefined) {
    return UNKNOWN;
  }

  if (typeof data === "boolean") {
    return data ? "Yes" : "No";
  }

  if (typeof data === "string") {
    // check for enum
    if (/[a-z]/.test(data[0])) {
      let parts = data.split("-");
      for (var i = 0; i < parts.length; i++) {
        if (EMUM_EXCEPTION_WORDS.indexOf(parts[i]) < 0) {
          parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].substr(1);
        }
      }
      return parts.join(" ");
    }
  }

  return data;
}

// ------------------------------------------------------
function getAddressDispay(data: any): any {
  var elements = [
    "care_of",
    "premises",
    "address_line_1",
    "address_line_2",
    "locality",
    "po_box",
    "region",
    "postal_code",
    "country",
  ];

  let address: any[] = [];
  elements.forEach(function (element) {
    if (data[element]) {
      address.push(data[element]);
    }
  });

  return getValueDisplay(address.join(" "));
}

// ------------------------------------------------------
function getDateDisplay(data: any): any {
  if (data == undefined) {
    return UNKNOWN;
  }

  if (typeof data === "string") {
    // we need to format a date string, e.g. 2012-01-03
    let [yeah, month, day] = data.split("-");
    return _getDate({
      day: day,
      month: month,
      year: yeah,
    });
  } else {
    // this already is a date object
    return _getDate(data);
  }
}

// function getSicCodeDisplay(data: any) : any {
//   return data + ' - ' + SIC[data];
// }

// ------------------------------------------------------
function pdfName(json: any): any {
  let parts = [
    json.company_number ? json.company_number : "",
    json.category ? json.category : "",
    json.significant_date ? json.significant_date.split("T")[0] : "",
  ];

  parts = parts.filter(function (n) {
    return n != "";
  });
  return parts.join("-") + ".pdf";
}

// ------------------------------------------------------
// Local Functions
// ------------------------------------------------------
function _getDate(data: any): any {
  if (data == undefined) {
    return UNKNOWN;
  }

  let day = data.day ? data.day : "";
  let month = data.month ? MONTHS[data.month - 1] : "";
  let year = data.year ? data.year : "";
  return [day, month, year]
    .filter(function (n) {
      return n != "";
    })
    .join(" ");
}

const SIC = {
  "0111": "Grow cereals & other crops",
  "0112": "Grow vegetables & nursery products",
  "0113": "Grow fruit, nuts, beverage & spice crops",
  "0121": "Farming of cattle, dairy farming",
  "0122": "Farm sheep, goats, horses, etc.",
  "0123": "Farming of swine",
  "0124": "Farming of poultry",
  "0125": "Other farming of animals",
  "0130": "Crops combined with animals, mixed farms",
  "0141": "Agricultural service activities",
  "0142": "Animal husbandry services, not vets",
  "0150": "Hunting and game rearing inc. services",
  "0201": "Forestry & logging",
  "0202": "Forestry & logging related services",
  "0501": "Fishing",
  "0502": "Operation of fish hatcheries & farms",
  "1010": "Mining & agglomeration of hard coal",
  "1020": "Mining and agglomeration of lignite",
  "1030": "Extraction and agglomeration of peat",
  "1110": "Extraction petroleum & natural gas",
  "1120": "Services to oil and gas extraction",
  "1200": "Mining of uranium & thorium ores",
  "1310": "Mining of iron ores",
  "1320": "Mining non-ferrous metal ores",
  "1411": "Quarrying of stone for construction",
  "1412": "Quarry of limestone, gypsum & chalk",
  "1413": "Quarrying of slate",
  "1421": "Operation of gravel and sand pits",
  "1422": "Mining of clays and kaolin",
  "1430": "Mine chemical & fertiliser minerals",
  "1440": "Production of salt",
  "1450": "Other mining and quarrying",
  "1511": "Production and preserving of meat",
  "1512": "Production & preserve poultry meat",
  "1513": "Production meat & poultry products",
  "1520": "Process & preserve fish & products",
  "1531": "Processing & preserve potatoes",
  "1532": "Manufacture of fruit & vegetable juice",
  "1533": "Process etc. fruit, vegetables",
  "1541": "Manufacture of crude oils and fats",
  "1542": "Manufacture of refined oils & fats",
  "1543": "Manufacture margarine & similar edible fats",
  "1551": "Operation dairies & cheese making",
  "1552": "Manufacture of ice cream",
  "1561": "Manufacture of grain mill products",
  "1562": "Manufacture of starches & starch products",
  "1571": "Manufacture of prepared farm animal feeds",
  "1572": "Manufacture of prepared pet foods",
  "1581": "Manufacture of bread, fresh pastry & cakes",
  "1582": "Manufacture biscuits, preserved pastry etc.",
  "1583": "Manufacture of sugar",
  "1584": "Manufacture cocoa, chocolate, confectionery",
  "1585": "Manufacture macaroni & similar farinaceous",
  "1586": "Processing of tea and coffee",
  "1587": "Manufacture of condiments & seasonings",
  "1588": "Manufacture of homogenised & dietetic food",
  "1589": "Manufacture of other food products",
  "1591": "Manufacture distilled potable alcoholic drinks",
  "1592": "Ethyl alcohol fermented materials",
  "1593": "Manufacture of wines",
  "1594": "Manufacture of cider & other fruit wines",
  "1595": "Manufacture other non-distilled fermented drinks",
  "1596": "Manufacture of beer",
  "1597": "Manufacture of malt",
  "1598": "Produce mineral water, soft drinks",
  "1600": "Manufacture of tobacco products",
  "1711": "Prepare & spin cotton-type fibres",
  "1712": "Prepare & spin woollen-type fibres",
  "1713": "Prepare & spin worsted-type fibres",
  "1714": "Preparation & spin flax-type fibres",
  "1715": "Throw & prepare silk, synthetic etc.",
  "1716": "Manufacture of sewing threads",
  "1717": "Preparation & spin of other textiles",
  "1721": "Cotton-type weaving",
  "1722": "Woollen-type weaving",
  "1723": "Worsted-type weaving",
  "1724": "Silk-type weaving",
  "1725": "Other textile weaving",
  "1730": "Finishing of textiles",
  "1740": "Manufacture made-up textiles, not apparel",
  "1751": "Manufacture of carpet and rugs",
  "1752": "Manufacture cordage, rope, twine & netting",
  "1753": "Manufacture nonwovens & goods, not apparel",
  "1754": "Manufacture of other textiles",
  "1760": "Manufacture of knitted & crocheted fabrics",
  "1771": "Manufacture of knitted & crocheted hosiery",
  "1772": "Manufacture knit & crocheted pullovers, etc.",
  "1810": "Manufacture of leather clothes",
  "1821": "Manufacture of workwear",
  "1822": "Manufacture of other outerwear",
  "1823": "Manufacture of underwear",
  "1824": "Manufacture other wearing apparel etc.",
  "1830": "Dress & dye fur; manufacture fur articles",
  "1910": "Tanning and dressing of leather",
  "1920": "Manufacture of luggage & the like, saddlery",
  "1930": "Manufacture of footwear",
  "2010": "Sawmill, plane, impregnation wood",
  "2020": "Manufacture of veneer sheets, plywood, etc.",
  "2030": "Manufacture builders carpentry & joinery",
  "2040": "Manufacture of wooden containers",
  "2051": "Manufacture of other products of wood",
  "2052": "Manufacture of articles of cork, straw etc.",
  "2111": "Manufacture of pulp",
  "2112": "Manufacture of paper & paperboard",
  "2121": "Manufacture of cartons",
  "2122": "Manufacture of household & toilet goods",
  "2123": "Manufacture of paper stationery",
  "2124": "Manufacture of wallpaper",
  "2125": "Manufacture of paper & paperboard goods",
  "2211": "Publishing of books",
  "2212": "Publishing of newspapers",
  "2213": "Publish journals & periodicals",
  "2214": "Publishing of sound recordings",
  "2215": "Other publishing",
  "2221": "Printing of newspapers",
  "2222": "Printing not elsewhere classified",
  "2223": "Bookbinding and finishing",
  "2224": "Pre-press activities",
  "2225": "Ancillary printing operations",
  "2231": "Reproduction of sound recording",
  "2232": "Reproduction of video recording",
  "2233": "Reproduction of computer media",
  "2310": "Manufacture of coke oven products",
  "2320": "Manufacture of refined petroleum products",
  "2330": "Processing of nuclear fuel",
  "2411": "Manufacture of industrial gases",
  "2412": "Manufacture of dyes and pigments",
  "2413": "Manufacture other inorganic basic chemicals",
  "2414": "Manufacture other organic basic chemicals",
  "2415": "Manufacture fertilisers, nitrogen compounds",
  "2416": "Manufacture of plastics in primary forms",
  "2417": "Manufacture synthetic rubber primary forms",
  "2420": "Manufacture of pesticides & agro-chemicals",
  "2430": "Manufacture of paints, print ink & mastics etc.",
  "2441": "Manufacture of basic pharmaceutical prods",
  "2442": "Manufacture of pharmaceutical preparations",
  "2451": "Manufacture soap & detergents, polishes etc.",
  "2452": "Manufacture perfumes & toilet preparations",
  "2461": "Manufacture of explosives",
  "2462": "Manufacture of glues and gelatines",
  "2463": "Manufacture of essential oils",
  "2464": "Manufacture photograph chemical material",
  "2465": "Manufacture of prepared unrecorded media",
  "2466": "Manufacture of other chemical products",
  "2470": "Manufacture of man-made fibres",
  "2511": "Manufacture of rubber tyres & tubes",
  "2512": "Retread & rebuild rubber tyres",
  "2513": "Manufacture of other rubber products",
  "2521": "Manufacture of plastic plates, sheets, etc.",
  "2522": "Manufacture of plastic pack goods",
  "2523": "Manufacture of builders ware of plastic",
  "2524": "Manufacture of other plastic products",
  "2611": "Manufacture of flat glass",
  "2612": "Shaping & process of flat glass",
  "2613": "Manufacture of hollow glass",
  "2614": "Manufacture of glass fibres",
  "2615": "Manufacture other glass inc. technical",
  "2621": "Manufacture of ceramic household etc. goods",
  "2622": "Manufacture of ceramic sanitary fixtures",
  "2623": "Manufacture of ceramic insulators etc.",
  "2624": "Manufacture other technical ceramic goods",
  "2625": "Manufacture of other ceramic products",
  "2626": "Manufacture of refractory ceramic products",
  "2630": "Manufacture of ceramic tiles & flags",
  "2640": "Manufacture of bricks, etc. in baked clay",
  "2651": "Manufacture of cement",
  "2652": "Manufacture of lime",
  "2653": "Manufacture of plaster",
  "2661": "Manufacture concrete goods for construction",
  "2662": "Manufacture plaster goods for construction",
  "2663": "Manufacture of ready-mixed concrete",
  "2664": "Manufacture of mortars",
  "2665": "Manufacture of fibre cement",
  "2666": "Manufacture other articles of concrete, etc.",
  "2670": "Cutting, shaping & finish stone",
  "2681": "Production of abrasive products",
  "2682": "Manufacture other non-metal mineral",
  "2710": "Manufacture of basic iron & steel & of Ferro-alloys ",
  "2721": "Manufacture of cast iron tubes",
  "2722": "Manufacture of steel tubes",
  "2731": "Cold drawing",
  "2732": "Cold rolling of narrow strips",
  "2733": "Cold forming or folding",
  "2734": "Wire drawing",
  "2735": "Other 1st process iron & steel",
  "2741": "Precious metals production",
  "2742": "Aluminium production",
  "2743": "Lead, zinc and tin production",
  "2744": "Copper production",
  "2745": "Other non-ferrous metal production",
  "2751": "Casting of iron",
  "2752": "Casting of steel",
  "2753": "Casting of light metals",
  "2754": "Casting of other non-ferrous metals",
  "2811": "Manufacture metal structures & parts",
  "2812": "Manufacture builders carpentry of metal",
  "2821": "Manufacture tanks, etc. & metal containers",
  "2822": "Manufacture central heating rads & boilers",
  "2830": "Manufacture steam generators, not boilers",
  "2840": "Forge press stamp & roll form metal",
  "2851": "Treatment and coat metals",
  "2852": "General mechanical engineering",
  "2861": "Manufacture of cutlery",
  "2862": "Manufacture of tools",
  "2863": "Manufacture of locks and hinges",
  "2871": "Manufacture steel drums, similar containers",
  "2872": "Manufacture of light metal packaging",
  "2873": "Manufacture of wire products",
  "2874": "Manufacture fasteners, screw, chains etc.",
  "2875": "Manufacture other fabricated metal products",
  "2911": "Manufacture engines, not aircraft, etc.",
  "2912": "Manufacture of pumps & compressors",
  "2913": "Manufacture of taps and valves",
  "2914": "Manufacture bearings, gears, gear etc.",
  "2921": "Manufacture of furnaces & furnace burners",
  "2922": "Manufacture of lift & handling equipment",
  "2923": "Manufacture non-domestic ventilation",
  "2924": "Manufacture of other general machinery",
  "2931": "Manufacture of agricultural tractors",
  "2932": "Manufacture other agric. & forestry machines",
  "2940": "Manufacture of machine tools",
  "2941": "Manufacture of portable hand held power tools",
  "2942": "Manufacture of metalworking tools",
  "2943": "Manufacture of other machine tools not elsewhere classified",
  "2951": "Manufacture of machinery for metallurgy",
  "2952": "Manufacture machines for mining, quarry etc.",
  "2953": "Manufacture for food, beverage & tobacco",
  "2954": "Manufacture for textile, apparel & leather",
  "2955": "Manufacture machinery for paper & board",
  "2956": "Manufacture other special purpose machine",
  "2960": "Manufacture of weapons & ammunition",
  "2971": "Manufacture of electric domestic appliances",
  "2972": "Manufacture non-electric domestic appliances",
  "3001": "Manufacture of office machinery",
  "3002": "Manufacture computers & process equipment",
  "3110": "Manufacture electric motors, generators etc.",
  "3120": "Manufacture electricity distribution etc.",
  "3130": "Manufacture of insulated wire & cable",
  "3140": "Manufacture of accumulators, batteries etc.",
  "3150": "Manufacture lighting equipment & lamps",
  "3161": "Manufacture electric equipment, engines etc.",
  "3162": "Manufacture other electrical equipment",
  "3210": "Manufacture of electronic components",
  "3220": "Manufacture TV transmitters, telephony etc.",
  "3230": "Manufacture TV & radio, sound or video etc.",
  "3310": "Manufacture medical, orthopaedic etc. equipment ",
  "3320": "Manufacture instruments for measuring etc.",
  "3330": "Manufacture indust process control equipment",
  "3340": "Manufacture optical, photographic etc. equipment ",
  "3350": "Manufacture of watches and clocks",
  "3410": "Manufacture of motor vehicles",
  "3420": "Manufacture motor vehicle bodies etc.",
  "3430": "Manufacture motor vehicle & engine parts",
  "3511": "Building and repairing of ships",
  "3512": "Build & repair pleasure & sport boats",
  "3520": "Manufacture of railway locomotives & stock",
  "3530": "Manufacture of aircraft & spacecraft",
  "3541": "Manufacture of motorcycles",
  "3542": "Manufacture of bicycles",
  "3543": "Manufacture of invalid carriages",
  "3550": "Manufacture other transport equipment",
  "3611": "Manufacture of chairs and seats",
  "3612": "Manufacture other office & shop furniture",
  "3613": "Manufacture of other kitchen furniture",
  "3614": "Manufacture of other furniture",
  "3615": "Manufacture of mattresses",
  "3621": "Striking of coins and medals",
  "3622": "Manufacture of jewellery & related",
  "3630": "Manufacture of musical instruments",
  "3640": "Manufacture of sports goods",
  "3650": "Manufacture of games and toys",
  "3661": "Manufacture of imitation jewellery",
  "3662": "Manufacture of brooms and brushes",
  "3663": "Other manufacturing",
  "3710": "Recycling of metal waste and scrap",
  "3720": "Recycling non-metal waste & scrap",
  "4010": "Production",
  "4011": "Production of electricity",
  "4012": "Transmission of electricity",
  "4013": "Distribution & trade in electricity",
  "4020": "Mfr of gas; mains distribution",
  "4021": "Manufacture of gas",
  "4022": "Distribution & trade of gaseous fuels through mains",
  "4030": "Steam and hot water supply",
  "4100": "Collection, purify etc. of water",
  "4511": "Demolition buildings; earth moving",
  "4512": "Test drilling and boring",
  "4521": "General construction & civil engineer",
  "4522": "Erection of roof covering & frames",
  "4523": "Construction roads, airfields etc.",
  "4524": "Construction of water projects",
  "4525": "Other special trades construction",
  "4531": "Installation electrical wiring etc.",
  "4532": "Insulation work activities",
  "4533": "Plumbing",
  "4534": "Other building installation",
  "4541": "Plastering",
  "4542": "Joinery installation",
  "4543": "Floor and wall covering",
  "4544": "Painting and glazing",
  "4545": "Other building completion",
  "4550": "Rent construction equipment with operator",
  "5010": "Sale of motor vehicles",
  "5020": "Maintenance & repair of motors",
  "5030": "Sale of motor vehicle parts etc.",
  "5040": "Sale, repair etc. motorcycles & parts",
  "5050": "Retail sale of automotive fuel",
  "5111": "Agents agric & textile materials",
  "5112": "Agents in sale of fuels, ores, etc.",
  "5113": "Agents in building materials",
  "5114": "Agents in industrial equipment, etc.",
  "5115": "Agents in household goods, etc.",
  "5116": "Agents in textiles, footwear etc.",
  "5117": "Agents in food, drink & tobacco",
  "5118": "Agents in particular products",
  "5119": "Agents in sale of variety of goods",
  "5121": "Wholesale of grain, animal feeds",
  "5122": "Wholesale of flowers and plants",
  "5123": "Wholesale of live animals",
  "5124": "Wholesale hides, skins and leather",
  "5125": "Wholesale of unmanufactured tobacco",
  "5131": "Wholesale of fruit and vegetables",
  "5132": "Wholesale of meat and meat products",
  "5133": "Wholesale dairy products",
  "5134": "Wholesale of alcohol and other drinks",
  "5135": "Wholesale of tobacco products",
  "5136": "Wholesale sugar, chocolate etc.",
  "5137": "Wholesale coffee, tea, cocoa etc.",
  "5138": "Wholesale other food inc fish, etc.",
  "5139": "Non-specialised wholesale food, etc.",
  "5141": "Wholesale of textiles",
  "5142": "Wholesale of clothing and footwear",
  "5143": "Wholesale electric household goods",
  "5144": "Wholesale of china, wallpaper etc.",
  "5145": "Wholesale of perfume and cosmetics",
  "5146": "Wholesale of pharmaceutical goods",
  "5147": "Wholesale of other household goods",
  "5151": "Wholesale fuels & related products",
  "5152": "Wholesale of metals and metal ores",
  "5153": "Wholesale wood, construction etc.",
  "5154": "Wholesale hardware, plumbing etc.",
  "5155": "Wholesale of chemical products",
  "5156": "Wholesale other intermediate goods",
  "5157": "Wholesale of waste and scrap",
  "5161": "Wholesale of machine tools",
  "5162": "Wholesale of construction machinery",
  "5163": "Wholesale textile industry machines",
  "5164": "Wholesale office machinery & equip",
  "5165": "Wholesale machines",
  "5166": "Wholesale agric machines & tractors",
  "5170": "Other wholesale",
  "5181": "Wholesale of machine tools",
  "5182": "Wholesale of mining",
  "5183": "Wholesale of machinery for the textile industry",
  "5184": "Wholesale of computers",
  "5185": "Wholesale of other office machinery & equipment",
  "5186": "Wholesale of other electronic parts & equipment",
  "5187": "Wholesale of other machinery for use in industry",
  "5188": "Wholesale of agricultural machinery & accessories & implements",
  "5190": "Other wholesale",
  "5211": "Retail in non-specialised stores holding an alcohol licence",
  "5212": "Other retail non-specialised stores",
  "5221": "Retail of fruit and vegetables",
  "5222": "Retail of meat and meat products",
  "5223": "Retail of fish, crustaceans etc.",
  "5224": "Retail bread, cakes, confectionery",
  "5225": "Retail alcoholic & other beverages",
  "5226": "Retail sale of tobacco products",
  "5227": "Other retail food etc. specialised",
  "5231": "Dispensing chemists",
  "5232": "Retail medical & orthopaedic goods",
  "5233": "Retail cosmetic & toilet articles",
  "5241": "Retail sale of textiles",
  "5242": "Retail sale of clothing",
  "5243": "Retail of footwear & leather goods",
  "5244": "Retail furniture household etc",
  "5245": "Retail electric household, etc. goods",
  "5246": "Retail hardware, paints & glass",
  "5247": "Retail books, newspapers etc.",
  "5248": "Other retail specialist stores",
  "5250": "Retail other secondhand goods",
  "5261": "Retail sale via mail order houses",
  "5262": "Retail sale via stalls and markets",
  "5263": "Other non-store retail sale",
  "5271": "Repair boots, shoes, leather goods",
  "5272": "Repair electrical household goods",
  "5273": "Repair of clocks & jewellery",
  "5274": "Repair not elsewhere classified",
  "5510": "Hotels & motels with or without restaurant",
  "5511": "Hotels & motels",
  "5512": "Hotels & motels",
  "5521": "Youth hostels and mountain refuges",
  "5522": "Camp sites, including caravan sites",
  "5523": "Other provision of lodgings",
  "5530": "Restaurants",
  "5540": "Bars",
  "5551": "Canteens",
  "5552": "Catering",
  "6010": "Transport via railways",
  "6021": "Other scheduled passenger land transport",
  "6022": "Taxi operation",
  "6023": "Other passenger land transport",
  "6024": "Freight transport by road",
  "6030": "Transport via pipelines",
  "6110": "Sea and coastal water transport",
  "6120": "Inland water transport",
  "6210": "Scheduled air transport",
  "6220": "Non-scheduled air transport",
  "6230": "Space transport",
  "6311": "Cargo handling",
  "6312": "Storage & warehousing",
  "6321": "Other supporting land transport",
  "6322": "Other supporting water transport",
  "6323": "Other supporting air transport",
  "6330": "Travel agencies etc; tourist",
  "6340": "Other transport agencies",
  "6411": "National post activities",
  "6412": "Courier other than national post",
  "6420": "Telecommunications",
  "6511": "Central banking",
  "6512": "Other monetary intermediation",
  "6521": "Financial leasing",
  "6522": "Other credit granting",
  "6523": "Other financial intermediation",
  "6601": "Life insurance/reinsurance",
  "6602": "Pension funding",
  "6603": "Non-life insurance/reinsurance",
  "6711": "Administration of financial markets",
  "6712": "Security broking & fund management",
  "6713": "Auxiliary financial intermed",
  "6720": "Auxiliary insurance & pension fund",
  "7011": "Development & sell real estate",
  "7012": "Buying & sell own real estate",
  "7020": "Letting of own property",
  "7031": "Real estate agencies",
  "7032": "Manage real estate, fee or contract",
  "7110": "Renting of automobiles",
  "7121": "Rent other land transport equipment",
  "7122": "Rent water transport equipment",
  "7123": "Renting of air transport equipment",
  "7131": "Rent agricultural machinery & equip",
  "7132": "Rent civil engineering machinery",
  "7133": "Rent office machinery inc computers",
  "7134": "Rent other machinery & equip",
  "7140": "Rent personal & household goods",
  "7210": "Hardware consultancy",
  "7220": "Software consultancy and supply",
  "7221": "Software publishing",
  "7222": "Other software consultancy and supply",
  "7230": "Data processing",
  "7240": "Data base activities",
  "7250": "Maintenance office & computing mach",
  "7260": "Other computer related activities",
  "7310": "R & d on nat sciences & engineering",
  "7320": "R & d on soc sciences & humanities",
  "7411": "Legal activities",
  "7412": "Accounting, auditing; tax consult",
  "7413": "Market research, opinion polling",
  "7414": "Business & management consultancy",
  "7415": "Holding companies including Head Offices",
  "7420": "Architectural, technical consult",
  "7430": "Technical testing and analysis",
  "7440": "Advertising",
  "7450": "Labour recruitment",
  "7460": "Investigation & security",
  "7470": "Other cleaning activities",
  "7481": "Portrait photographic activities",
  "7482": "Packaging activities",
  "7483": "Secretarial & translation",
  "7484": "Other business activities",
  "7485": "Secretarial & translation services",
  "7486": "Call centre activities",
  "7487": "Other business activities",
  "7499": "Non-trading company",
  "7511": "General (overall) public service",
  "7512": "Regulation health, education, etc.",
  "7513": "Regulation more efficient business",
  "7514": "Support services for government",
  "7521": "Foreign affairs",
  "7522": "Defence activities",
  "7523": "Justice and judicial activities",
  "7524": "Public security, law & order",
  "7525": "Fire service activities",
  "7530": "Compulsory social security",
  "8010": "Primary education",
  "8021": "General secondary education",
  "8022": "Technical & vocational secondary",
  "8030": "Higher education",
  "8041": "Driving school activities",
  "8042": "Adult and other education",
  "8511": "Hospital activities",
  "8512": "Medical practice activities",
  "8513": "Dental practice activities",
  "8514": "Other human health activities",
  "8520": "Veterinary activities",
  "8531": "Social work with accommodation",
  "8532": "Social work without accommodation",
  "9000": "Refuse disposal",
  "9001": "Collection and treatment of sewage",
  "9002": "Collection and treatment of other waste",
  "9003": "Sanitation remediation and similar activities",
  "9111": "Business & employers organisations",
  "9112": "Professional organisations",
  "9120": "Trade unions",
  "9131": "Religious organisations",
  "9132": "Political organisations",
  "9133": "Other membership organisations",
  "9211": "Motion picture and video production",
  "9212": "Motion picture & video distribution",
  "9213": "Motion picture projection",
  "9220": "Radio and television activities",
  "9231": "Artistic & literary creation etc",
  "9232": "Operation of arts facilities",
  "9233": "Fair and amusement park activities",
  "9234": "Other entertainment activities",
  "9240": "News agency activities",
  "9251": "Library and archives activities",
  "9252": "Museum & preservation of history",
  "9253": "Botanical, zoos & nature reserves",
  "9261": "Operate sports arenas & stadiums",
  "9262": "Other sporting activities",
  "9271": "Gambling and betting activities",
  "9272": "Other recreational activities nec",
  "9301": "Wash & dry clean textile & fur",
  "9302": "Hairdressing & other beauty treatment",
  "9303": "Funeral and related activities",
  "9304": "Physical well-being activities",
  "9305": "Other service activities n.e.c.",
  "9500": "Private household with employees",
  "9600":
    "Undifferentiated goods producing activities of private households for own use",
  "9700":
    "Undifferentiated services producing activities of private households for own use",
  "9800": "Residents property management",
  "9900": "Extra-territorial organisations",
  "9999": "Dormant company",
  "01110": "Growing of cereals (except rice), leguminous crops and oil seeds",
  "01120": "Growing of rice",
  "01130": "Growing of vegetables and melons, roots and tubers",
  "01140": "Growing of sugar cane",
  "01150": "Growing of tobacco",
  "01160": "Growing of fibre crops",
  "01190": "Growing of other non-perennial crops",
  "01210": "Growing of grapes",
  "01220": "Growing of tropical and subtropical fruits",
  "01230": "Growing of citrus fruits",
  "01240": "Growing of pome fruits and stone fruits",
  "01250": "Growing of other tree and bush fruits and nuts",
  "01260": "Growing of oleaginous fruits",
  "01270": "Growing of beverage crops",
  "01280": "Growing of spices, aromatic, drug and pharmaceutical crops",
  "01290": "Growing of other perennial crops",
  "01300": "Plant propagation",
  "01410": "Raising of dairy cattle",
  "01420": "Raising of other cattle and buffaloes",
  "01430": "Raising of horses and other equines",
  "01440": "Raising of camels and camelids",
  "01450": "Raising of sheep and  goats",
  "01460": "Raising of swine/pigs",
  "01470": "Raising of poultry",
  "01490": "Raising of other animals",
  "01500": "Mixed farming",
  "01610": "Support activities for crop production",
  "01621": "Farm animal boarding and care",
  "01629":
    "Support activities for animal production (other than farm animal boarding and care) not elsewhere classified",
  "01630": "Post-harvest crop activities",
  "01640": "Seed processing for propagation",
  "01700": "Hunting, trapping and related service activities",
  "02100": "Silviculture and other forestry activities",
  "02200": "Logging",
  "02300": "Gathering of wild growing non-wood products",
  "02400": "Support services to forestry",
  "03110": "Marine fishing",
  "03120": "Freshwater fishing",
  "03210": "Marine aquaculture",
  "03220": "Freshwater aquaculture",
  "05101": "Deep coal mines",
  "05102": "Open cast coal working",
  "05200": "Mining of lignite",
  "06100": "Extraction of crude petroleum",
  "06200": "Extraction of natural gas",
  "07100": "Mining of iron ores",
  "07210": "Mining of uranium and thorium ores",
  "07290": "Mining of other non-ferrous metal ores",
  "08110":
    "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate",
  "08120": "Operation of gravel and sand pits; mining of clays and kaolin",
  "08910": "Mining of chemical and fertiliser minerals",
  "08920": "Extraction of peat",
  "08930": "Extraction of salt",
  "08990": "Other mining and quarrying not elsewhere classified",
  "09100": "Support activities for petroleum and natural gas extraction",
  "09900": "Support activities for other mining and quarrying",
  "10110": "Processing and preserving of meat",
  "10120": "Processing and preserving of poultry meat",
  "10130": "Production of meat and poultry meat products",
  "10200": "Processing and preserving of fish, crustaceans and molluscs",
  "10310": "Processing and preserving of potatoes",
  "10320": "Manufacture of fruit and vegetable juice",
  "10390": "Other processing and preserving of fruit and vegetables",
  "10410": "Manufacture of oils and fats",
  "10420": "Manufacture of margarine and similar edible fats",
  "10511": "Liquid milk and cream production",
  "10512": "Butter and cheese production",
  "10519": "Manufacture of other milk products",
  "10520": "Manufacture of ice cream",
  "10611": "Grain milling",
  "10612": "Manufacture of breakfast cereals and cereals-based food",
  "10620": "Manufacture of starches and starch products",
  "10710": "Manufacture of bread; manufacture of fresh pastry goods and cakes",
  "10720":
    "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes",
  "10730":
    "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
  "10810": "Manufacture of sugar",
  "10821": "Manufacture of cocoa and chocolate confectionery",
  "10822": "Manufacture of sugar confectionery",
  "10831": "Tea processing",
  "10832": "Production of coffee and coffee substitutes",
  "10840": "Manufacture of condiments and seasonings",
  "10850": "Manufacture of prepared meals and dishes",
  "10860": "Manufacture of homogenised food preparations and dietetic food",
  "10890": "Manufacture of other food products not elsewhere classified",
  "10910": "Manufacture of prepared feeds for farm animals",
  "10920": "Manufacture of prepared pet foods",
  "11010": "Distilling, rectifying and blending of spirits",
  "11020": "Manufacture of wine from grape",
  "11030": "Manufacture of cider and other fruit wines",
  "11040": "Manufacture of other non-distilled fermented beverages",
  "11050": "Manufacture of beer",
  "11060": "Manufacture of malt",
  "11070":
    "Manufacture of soft drinks; production of mineral waters and other bottled waters",
  "12000": "Manufacture of tobacco products",
  "13100": "Preparation and spinning of textile fibres",
  "13200": "Weaving of textiles",
  "13300": "Finishing of textiles",
  "13910": "Manufacture of knitted and crocheted fabrics",
  "13921": "Manufacture of soft furnishings",
  "13922": "manufacture of canvas goods, sacks, etc.",
  "13923": "manufacture of household textiles",
  "13931": "Manufacture of woven or tufted carpets and rugs",
  "13939": "Manufacture of other carpets and rugs",
  "13940": "Manufacture of cordage, rope, twine and netting",
  "13950":
    "Manufacture of non-wovens and articles made from non-wovens, except apparel",
  "13960": "Manufacture of other technical and industrial textiles",
  "13990": "Manufacture of other textiles not elsewhere classified",
  "14110": "Manufacture of leather clothes",
  "14120": "Manufacture of workwear",
  "14131": "Manufacture of other men's outerwear",
  "14132": "Manufacture of other women's outerwear",
  "14141": "Manufacture of men's underwear",
  "14142": "Manufacture of women's underwear",
  "14190":
    "Manufacture of other wearing apparel and accessories not elsewhere classified",
  "14200": "Manufacture of articles of fur",
  "14310": "Manufacture of knitted and crocheted hosiery",
  "14390": "Manufacture of other knitted and crocheted apparel",
  "15110": "Tanning and dressing of leather; dressing and dyeing of fur",
  "15120":
    "Manufacture of luggage, handbags and the like, saddlery and harness",
  "15200": "Manufacture of footwear",
  "16100": "Sawmilling and planing of wood",
  "16210": "Manufacture of veneer sheets and wood-based panels",
  "16220": "Manufacture of assembled parquet floors",
  "16230": "Manufacture of other builders' carpentry and joinery",
  "16240": "Manufacture of wooden containers",
  "16290":
    "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
  "17110": "Manufacture of pulp",
  "17120": "Manufacture of paper and paperboard",
  "17211": "Manufacture of corrugated paper and paperboard, sacks and bags",
  "17219": "Manufacture of other paper and paperboard containers",
  "17220":
    "Manufacture of household and sanitary goods and of toilet requisites",
  "17230": "Manufacture of paper stationery",
  "17240": "Manufacture of wallpaper",
  "17290":
    "Manufacture of other articles of paper and paperboard not elsewhere classified",
  "18110": "Printing of newspapers",
  "18121": "Manufacture of printed labels",
  "18129": "Printing not elsewhere classified",
  "18130": "Pre-press and pre-media services",
  "18140": "Binding and related services",
  "18201": "Reproduction of sound recording",
  "18202": "Reproduction of video recording",
  "18203": "Reproduction of computer media",
  "19100": "Manufacture of coke oven products",
  "19201": "Mineral oil refining",
  "19209":
    "Other treatment of petroleum products (excluding petrochemicals manufacture)",
  "20110": "Manufacture of industrial gases",
  "20120": "Manufacture of dyes and pigments",
  "20130": "Manufacture of other inorganic basic chemicals",
  "20140": "Manufacture of other organic basic chemicals",
  "20150": "Manufacture of fertilisers and nitrogen compounds",
  "20160": "Manufacture of plastics in primary forms",
  "20170": "Manufacture of synthetic rubber in primary forms",
  "20200": "Manufacture of pesticides and other agrochemical products",
  "20301":
    "Manufacture of paints, varnishes and similar coatings, mastics and sealants",
  "20302": "Manufacture of printing ink",
  "20411": "Manufacture of soap and detergents",
  "20412": "Manufacture of cleaning and polishing preparations",
  "20420": "Manufacture of perfumes and toilet preparations",
  "20510": "Manufacture of explosives",
  "20520": "Manufacture of glues",
  "20530": "Manufacture of essential oils",
  "20590": "Manufacture of other chemical products not elsewhere classified",
  "20600": "Manufacture of man-made fibres",
  "21100": "Manufacture of basic pharmaceutical products",
  "21200": "Manufacture of pharmaceutical preparations",
  "22110":
    "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
  "22190": "Manufacture of other rubber products",
  "22210": "Manufacture of plastic plates, sheets, tubes and profiles",
  "22220": "Manufacture of plastic packing goods",
  "22230": "Manufacture of builders  ware of plastic",
  "22290": "Manufacture of other plastic products",
  "23110": "Manufacture of flat glass",
  "23120": "Shaping and processing of flat glass",
  "23130": "Manufacture of hollow glass",
  "23140": "Manufacture of glass fibres",
  "23190":
    "Manufacture and processing of other glass, including technical glassware",
  "23200": "Manufacture of refractory products",
  "23310": "Manufacture of ceramic tiles and flags",
  "23320":
    "Manufacture of bricks, tiles and construction products, in baked clay",
  "23410": "Manufacture of ceramic household and ornamental articles",
  "23420": "Manufacture of ceramic sanitary fixtures",
  "23430": "Manufacture of ceramic insulators and insulating fittings",
  "23440": "Manufacture of other technical ceramic products",
  "23490": "Manufacture of other ceramic products not elsewhere classified",
  "23510": "Manufacture of cement",
  "23520": "Manufacture of lime and plaster",
  "23610": "Manufacture of concrete products for construction purposes",
  "23620": "Manufacture of plaster products for construction purposes",
  "23630": "Manufacture of ready-mixed concrete",
  "23640": "Manufacture of mortars",
  "23650": "Manufacture of fibre cement",
  "23690": "Manufacture of other articles of concrete, plaster and cement",
  "23700": "Cutting, shaping and finishing of stone",
  "23910": "Production of abrasive products",
  "23990":
    "Manufacture of other non-metallic mineral products not elsewhere classified",
  "24100": "Manufacture of basic iron and steel and of ferro-alloys",
  "24200":
    "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel",
  "24310": "Cold drawing of bars",
  "24320": "Cold rolling of narrow strip",
  "24330": "Cold forming or folding",
  "24340": "Cold drawing of wire",
  "24410": "Precious metals production",
  "24420": "Aluminium production",
  "24430": "Lead, zinc and tin production",
  "24440": "Copper production",
  "24450": "Other non-ferrous metal production",
  "24460": "Processing of nuclear fuel",
  "24510": "Casting of iron",
  "24520": "Casting of steel",
  "24530": "Casting of light metals",
  "24540": "Casting of other non-ferrous metals",
  "25110": "Manufacture of metal structures and parts of structures",
  "25120": "Manufacture of doors and windows of metal",
  "25210": "Manufacture of central heating radiators and boilers",
  "25290": "Manufacture of other tanks, reservoirs and containers of metal",
  "25300":
    "Manufacture of steam generators, except central heating hot water boilers",
  "25400": "Manufacture of weapons and ammunition",
  "25500":
    "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
  "25610": "Treatment and coating of metals",
  "25620": "Machining",
  "25710": "Manufacture of cutlery",
  "25720": "Manufacture of locks and hinges",
  "25730": "Manufacture of tools",
  "25910": "Manufacture of steel drums and similar containers",
  "25920": "Manufacture of light metal packaging",
  "25930": "Manufacture of wire products, chain and springs",
  "25940": "Manufacture of fasteners and screw machine products",
  "25990":
    "Manufacture of other fabricated metal products not elsewhere classified",
  "26110": "Manufacture of electronic components",
  "26120": "Manufacture of loaded electronic boards",
  "26200": "Manufacture of computers and peripheral equipment",
  "26301": "Manufacture of telegraph and telephone apparatus and equipment",
  "26309":
    "Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment",
  "26400": "Manufacture of consumer electronics",
  "26511":
    "Manufacture of electronic measuring, testing etc. equipment, not for industrial process control",
  "26512": "Manufacture of electronic industrial process control equipment",
  "26513":
    "Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control",
  "26514": "Manufacture of non-electronic industrial process control equipment",
  "26520": "Manufacture of watches and clocks",
  "26600":
    "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
  "26701": "Manufacture of optical precision instruments",
  "26702": "Manufacture of photographic and cinematographic equipment",
  "26800": "Manufacture of magnetic and optical media",
  "27110": "Manufacture of electric motors, generators and transformers",
  "27120": "Manufacture of electricity distribution and control apparatus",
  "27200": "Manufacture of batteries and accumulators",
  "27310": "Manufacture of fibre optic cables",
  "27320": "Manufacture of other electronic and electric wires and cables",
  "27330": "Manufacture of wiring devices",
  "27400": "Manufacture of electric lighting equipment",
  "27510": "Manufacture of electric domestic appliances",
  "27520": "Manufacture of non-electric domestic appliances",
  "27900": "Manufacture of other electrical equipment",
  "28110":
    "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
  "28120": "Manufacture of fluid power equipment",
  "28131": "Manufacture of pumps",
  "28132": "Manufacture of compressors",
  "28140": "Manufacture of taps and valves",
  "28150": "Manufacture of bearings, gears, gearing and driving elements",
  "28210": "Manufacture of ovens, furnaces and furnace burners",
  "28220": "Manufacture of lifting and handling equipment",
  "28230":
    "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
  "28240": "Manufacture of power-driven hand tools",
  "28250": "Manufacture of non-domestic cooling and ventilation equipment",
  "28290":
    "Manufacture of other general-purpose machinery not elsewhere classified",
  "28301": "Manufacture of agricultural tractors",
  "28302":
    "Manufacture of agricultural and forestry machinery other than tractors",
  "28410": "Manufacture of metal forming machinery",
  "28490": "Manufacture of other machine tools",
  "28910": "Manufacture of machinery for metallurgy",
  "28921": "Manufacture of machinery for mining",
  "28922": "Manufacture of earthmoving equipment",
  "28923":
    "Manufacture of equipment for concrete crushing and screening and roadworks",
  "28930": "Manufacture of machinery for food, beverage and tobacco processing",
  "28940":
    "Manufacture of machinery for textile, apparel and leather production",
  "28950": "Manufacture of machinery for paper and paperboard production",
  "28960": "Manufacture of plastics and rubber machinery",
  "28990":
    "Manufacture of other special-purpose machinery not elsewhere classified",
  "29100": "Manufacture of motor vehicles",
  "29201":
    "Manufacture of bodies (coachwork) for motor vehicles (except caravans)",
  "29202": "Manufacture of trailers and semi-trailers",
  "29203": "Manufacture of caravans",
  "29310":
    "Manufacture of electrical and electronic equipment for motor vehicles and their engines",
  "29320": "Manufacture of other parts and accessories for motor vehicles",
  "30110": "Building of ships and floating structures",
  "30120": "Building of pleasure and sporting boats",
  "30200": "Manufacture of railway locomotives and rolling stock",
  "30300": "Manufacture of air and spacecraft and related machinery",
  "30400": "Manufacture of military fighting vehicles",
  "30910": "Manufacture of motorcycles",
  "30920": "Manufacture of bicycles and invalid carriages",
  "30990": "Manufacture of other transport equipment not elsewhere classified",
  "31010": "Manufacture of office and shop furniture",
  "31020": "Manufacture of kitchen furniture",
  "31030": "Manufacture of mattresses",
  "31090": "Manufacture of other furniture",
  "32110": "Striking of coins",
  "32120": "Manufacture of jewellery and related articles",
  "32130": "Manufacture of imitation jewellery and related articles",
  "32200": "Manufacture of musical instruments",
  "32300": "Manufacture of sports goods",
  "32401": "Manufacture of professional and arcade games and toys",
  "32409": "Manufacture of other games and toys, not elsewhere classified",
  "32500": "Manufacture of medical and dental instruments and supplies",
  "32910": "Manufacture of brooms and brushes",
  "32990": "Other manufacturing not elsewhere classified",
  "33110": "Repair of fabricated metal products",
  "33120": "Repair of machinery",
  "33130": "Repair of electronic and optical equipment",
  "33140": "Repair of electrical equipment",
  "33150": "Repair and maintenance of ships and boats",
  "33160": "Repair and maintenance of aircraft and spacecraft",
  "33170":
    "Repair and maintenance of other transport equipment not elsewhere classified",
  "33190": "Repair of other equipment",
  "33200": "Installation of industrial machinery and equipment",
  "35110": "Production of electricity",
  "35120": "Transmission of electricity",
  "35130": "Distribution of electricity",
  "35140": "Trade of electricity",
  "35210": "Manufacture of gas",
  "35220": "Distribution of gaseous fuels through mains",
  "35230": "Trade of gas through mains",
  "35300": "Steam and air conditioning supply",
  "36000": "Water collection, treatment and supply",
  "37000": "Sewerage",
  "38110": "Collection of non-hazardous waste",
  "38120": "Collection of hazardous waste",
  "38210": "Treatment and disposal of non-hazardous waste",
  "38220": "Treatment and disposal of hazardous waste",
  "38310": "Dismantling of wrecks",
  "38320": "Recovery of sorted materials",
  "39000": "Remediation activities and other waste management services",
  "41100": "Development of building projects",
  "41201": "Construction of commercial buildings",
  "41202": "Construction of domestic buildings",
  "42110": "Construction of roads and motorways",
  "42120": "Construction of railways and underground railways",
  "42130": "Construction of bridges and tunnels",
  "42210": "Construction of utility projects for fluids",
  "42220":
    "Construction of utility projects for electricity and telecommunications",
  "42910": "Construction of water projects",
  "42990":
    "Construction of other civil engineering projects not elsewhere classified",
  "43110": "Demolition",
  "43120": "Site preparation",
  "43130": "Test drilling and boring",
  "43210": "Electrical installation",
  "43220": "Plumbing, heat and air-conditioning installation",
  "43290": "Other construction installation",
  "43310": "Plastering",
  "43320": "Joinery installation",
  "43330": "Floor and wall covering",
  "43341": "Painting",
  "43342": "Glazing",
  "43390": "Other building completion and finishing",
  "43910": "Roofing activities",
  "43991": "Scaffold erection",
  "43999": "Other specialised construction activities not elsewhere classified",
  "45111": "Sale of new cars and light motor vehicles",
  "45112": "Sale of used cars and light motor vehicles",
  "45190": "Sale of other motor vehicles",
  "45200": "Maintenance and repair of motor vehicles",
  "45310": "Wholesale trade of motor vehicle parts and accessories",
  "45320": "Retail trade of motor vehicle parts and accessories",
  "45400":
    "Sale, maintenance and repair of motorcycles and related parts and accessories",
  "46110":
    "Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods",
  "46120":
    "Agents involved in the sale of fuels, ores, metals and industrial chemicals",
  "46130": "Agents involved in the sale of timber and building materials",
  "46140":
    "Agents involved in the sale of machinery, industrial equipment, ships and aircraft",
  "46150":
    "Agents involved in the sale of furniture, household goods, hardware and ironmongery",
  "46160":
    "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods",
  "46170": "Agents involved in the sale of food, beverages and tobacco",
  "46180": "Agents specialised in the sale of other particular products",
  "46190": "Agents involved in the sale of a variety of goods",
  "46210": "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds",
  "46220": "Wholesale of flowers and plants",
  "46230": "Wholesale of live animals",
  "46240": "Wholesale of hides, skins and leather",
  "46310": "Wholesale of fruit and vegetables",
  "46320": "Wholesale of meat and meat products",
  "46330": "Wholesale of dairy products, eggs and edible oils and fats",
  "46341":
    "Wholesale of fruit and vegetable juices, mineral water and soft drinks",
  "46342": "Wholesale of wine, beer, spirits and other alcoholic beverages",
  "46350": "Wholesale of tobacco products",
  "46360": "Wholesale of sugar and chocolate and sugar confectionery",
  "46370": "Wholesale of coffee, tea, cocoa and spices",
  "46380": "Wholesale of other food, including fish, crustaceans and molluscs",
  "46390": "Non-specialised wholesale of food, beverages and tobacco",
  "46410": "Wholesale of textiles",
  "46420": "Wholesale of clothing and footwear",
  "46431":
    "Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played",
  "46439":
    "Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)",
  "46440": "Wholesale of china and glassware and cleaning materials",
  "46450": "Wholesale of perfume and cosmetics",
  "46460": "Wholesale of pharmaceutical goods",
  "46470": "Wholesale of furniture, carpets and lighting equipment",
  "46480": "Wholesale of watches and jewellery",
  "46491": "Wholesale of musical instruments",
  "46499":
    "Wholesale of household goods (other than musical instruments) not elsewhere classified",
  "46510": "Wholesale of computers, computer peripheral equipment and software",
  "46520": "Wholesale of electronic and telecommunications equipment and parts",
  "46610": "Wholesale of agricultural machinery, equipment and supplies",
  "46620": "Wholesale of machine tools",
  "46630": "Wholesale of mining, construction and civil engineering machinery",
  "46640":
    "Wholesale of machinery for the textile industry and of sewing and knitting machines",
  "46650": "Wholesale of office furniture",
  "46660": "Wholesale of other office machinery and equipment",
  "46690": "Wholesale of other machinery and equipment",
  "46711": "Wholesale of petroleum and petroleum products",
  "46719": "Wholesale of other fuels and related products",
  "46720": "Wholesale of metals and metal ores",
  "46730": "Wholesale of wood, construction materials and sanitary equipment",
  "46740": "Wholesale of hardware, plumbing and heating equipment and supplies",
  "46750": "Wholesale of chemical products",
  "46760": "Wholesale of other intermediate products",
  "46770": "Wholesale of waste and scrap",
  "46900": "Non-specialised wholesale trade",
  "47110":
    "Retail sale in non-specialised stores with food, beverages or tobacco predominating",
  "47190": "Other retail sale in non-specialised stores",
  "47210": "Retail sale of fruit and vegetables in specialised stores",
  "47220": "Retail sale of meat and meat products in specialised stores",
  "47230":
    "Retail sale of fish, crustaceans and molluscs in specialised stores",
  "47240":
    "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores",
  "47250": "Retail sale of beverages in specialised stores",
  "47260": "Retail sale of tobacco products in specialised stores",
  "47290": "Other retail sale of food in specialised stores",
  "47300": "Retail sale of automotive fuel in specialised stores",
  "47410":
    "Retail sale of computers, peripheral units and software in specialised stores",
  "47421": "Retail sale of mobile telephones",
  "47429":
    "Retail sale of telecommunications equipment other than mobile telephones",
  "47430": "Retail sale of audio and video equipment in specialised stores",
  "47510": "Retail sale of textiles in specialised stores",
  "47520": "Retail sale of hardware, paints and glass in specialised stores",
  "47530":
    "Retail sale of carpets, rugs, wall and floor coverings in specialised stores",
  "47540":
    "Retail sale of electrical household appliances in specialised stores",
  "47591": "Retail sale of musical instruments and scores",
  "47599":
    "Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store",
  "47610": "Retail sale of books in specialised stores",
  "47620": "Retail sale of newspapers and stationery in specialised stores",
  "47630": "Retail sale of music and video recordings in specialised stores",
  "47640":
    "Retail sale of sports goods, fishing gear, camping goods, boats and bicycles",
  "47650": "Retail sale of games and toys in specialised stores",
  "47710": "Retail sale of clothing in specialised stores",
  "47721": "Retail sale of footwear in specialised stores",
  "47722": "Retail sale of leather goods in specialised stores",
  "47730": "Dispensing chemist in specialised stores",
  "47741": "Retail sale of hearing aids",
  "47749":
    "Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) not elsewhere classified",
  "47750": "Retail sale of cosmetic and toilet articles in specialised stores",
  "47760":
    "Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores",
  "47770": "Retail sale of watches and jewellery in specialised stores",
  "47781": "Retail sale in commercial art galleries",
  "47782": "Retail sale by opticians",
  "47789":
    "Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)",
  "47791": "Retail sale of antiques including antique books in stores",
  "47799":
    "Retail sale of other second-hand goods in stores (not incl. antiques)",
  "47810":
    "Retail sale via stalls and markets of food, beverages and tobacco products",
  "47820":
    "Retail sale via stalls and markets of textiles, clothing and footwear",
  "47890": "Retail sale via stalls and markets of other goods",
  "47910": "Retail sale via mail order houses or via Internet",
  "47990": "Other retail sale not in stores, stalls or markets",
  "49100": "Passenger rail transport, interurban",
  "49200": "Freight rail transport",
  "49311":
    "Urban and suburban passenger railway transportation by underground, metro and similar systems",
  "49319":
    "Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)",
  "49320": "Taxi operation",
  "49390": "Other passenger land transport",
  "49410": "Freight transport by road",
  "49420": "Removal services",
  "49500": "Transport via pipeline",
  "50100": "Sea and coastal passenger water transport",
  "50200": "Sea and coastal freight water transport",
  "50300": "Inland passenger water transport",
  "50400": "Inland freight water transport",
  "51101": "Scheduled passenger air transport",
  "51102": "Non-scheduled passenger air transport",
  "51210": "Freight air transport",
  "51220": "Space transport",
  "52101":
    "Operation of warehousing and storage facilities for water transport activities",
  "52102":
    "Operation of warehousing and storage facilities for air transport activities",
  "52103":
    "Operation of warehousing and storage facilities for land transport activities",
  "52211": "Operation of rail freight terminals",
  "52212": "Operation of rail passenger facilities at railway stations",
  "52213":
    "Operation of bus and coach passenger facilities at bus and coach stations",
  "52219":
    "Other service activities incidental to land transportation, not elsewhere classified",
  "52220": "Service activities incidental to water transportation",
  "52230": "Service activities incidental to air transportation",
  "52241": "Cargo handling for water transport activities",
  "52242": "Cargo handling for air transport activities",
  "52243": "Cargo handling for land transport activities",
  "52290": "Other transportation support activities",
  "53100": "Postal activities under universal service obligation",
  "53201": "Licensed carriers",
  "53202": "Unlicensed carrier",
  "55100": "Hotels and similar accommodation",
  "55201": "Holiday centres and villages",
  "55202": "Youth hostels",
  "55209": "Other holiday and other collective accommodation",
  "55300": "Recreational vehicle parks, trailer parks and camping grounds",
  "55900": "Other accommodation",
  "56101": "Licensed restaurants",
  "56102": "Unlicensed restaurants and cafes",
  "56103": "Take-away food shops and mobile food stands",
  "56210": "Event catering activities",
  "56290": "Other food services",
  "56301": "Licensed clubs",
  "56302": "Public houses and bars",
  "58110": "Book publishing",
  "58120": "Publishing of directories and mailing lists",
  "58130": "Publishing of newspapers",
  "58141": "Publishing of learned journals",
  "58142": "Publishing of  consumer and business journals and periodicals",
  "58190": "Other publishing activities",
  "58210": "Publishing of computer games",
  "58290": "Other software publishing",
  "59111": "Motion picture production activities",
  "59112": "Video production activities",
  "59113": "Television programme production activities",
  "59120":
    "Motion picture, video and television programme post-production activities",
  "59131": "Motion picture distribution activities",
  "59132": "Video distribution activities",
  "59133": "Television programme distribution activities",
  "59140": "Motion picture projection activities",
  "59200": "Sound recording and music publishing activities",
  "60100": "Radio broadcasting",
  "60200": "Television programming and broadcasting activities",
  "61100": "Wired telecommunications activities",
  "61200": "Wireless telecommunications activities",
  "61300": "Satellite telecommunications activities",
  "61900": "Other telecommunications activities",
  "62011":
    "Ready-made interactive leisure and entertainment software development",
  "62012": "Business and domestic software development",
  "62020": "Information technology consultancy activities",
  "62030": "Computer facilities management activities",
  "62090": "Other information technology service activities",
  "63110": "Data processing, hosting and related activities",
  "63120": "Web portals",
  "63910": "News agency activities",
  "63990": "Other information service activities not elsewhere classified",
  "64110": "Central banking",
  "64191": "Banks",
  "64192": "Building societies",
  "64201": "Activities of agricultural holding companies",
  "64202": "Activities of production holding companies",
  "64203": "Activities of construction holding companies",
  "64204": "Activities of distribution holding companies",
  "64205": "Activities of financial services holding companies",
  "64209": "Activities of other holding companies not elsewhere classified",
  "64301": "Activities of investment trusts",
  "64302": "Activities of unit trusts",
  "64303": "Activities of venture and development capital companies",
  "64304": "Activities of open-ended investment companies",
  "64305": "Activities of property unit trusts",
  "64306": "Activities of real estate investment trusts",
  "64910": "Financial leasing",
  "64921":
    "Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors",
  "64922": "Activities of mortgage finance companies",
  "64929": "Other credit granting not elsewhere classified",
  "64991": "Security dealing on own account",
  "64992": "Factoring",
  "64999": "Financial intermediation not elsewhere classified",
  "65110": "Life insurance",
  "65120": "Non-life insurance",
  "65201": "Life reinsurance",
  "65202": "Non-life reinsurance",
  "65300": "Pension funding",
  "66110": "Administration of financial markets",
  "66120": "Security and commodity contracts dealing activities",
  "66190":
    "Activities auxiliary to financial intermediation not elsewhere classified",
  "66210": "Risk and damage evaluation",
  "66220": "Activities of insurance agents and brokers",
  "66290": "Other activities auxiliary to insurance and pension funding",
  "66300": "Fund management activities",
  "68100": "Buying and selling of own real estate",
  "68201": "Renting and operating of Housing Association real estate",
  "68202": "Letting and operating of conference and exhibition centres",
  "68209": "Other letting and operating of own or leased real estate",
  "68310": "Real estate agencies",
  "68320": "Management of real estate on a fee or contract basis",
  "69101": "Barristers at law",
  "69102": "Solicitors",
  "69109":
    "Activities of patent and copyright agents; other legal activities not elsewhere classified",
  "69201": "Accounting and auditing activities",
  "69202": "Bookkeeping activities",
  "69203": "Tax consultancy",
  "70100": "Activities of head offices",
  "70210": "Public relations and communications activities",
  "70221": "Financial management",
  "70229": "Management consultancy activities other than financial management",
  "71111": "Architectural activities",
  "71112": "Urban planning and landscape architectural activities",
  "71121":
    "Engineering design activities for industrial process and production",
  "71122": "Engineering related scientific and technical consulting activities",
  "71129": "Other engineering activities",
  "71200": "Technical testing and analysis",
  "72110": "Research and experimental development on biotechnology",
  "72190":
    "Other research and experimental development on natural sciences and engineering",
  "72200":
    "Research and experimental development on social sciences and humanities",
  "73110": "Advertising agencies",
  "73120": "Media representation services",
  "73200": "Market research and public opinion polling",
  "74100": "Specialised design activities",
  "74201": "Portrait photographic activities",
  "74202": "Other specialist photography",
  "74203": "Film processing",
  "74209": "Photographic activities not elsewhere classified",
  "74300": "Translation and interpretation activities",
  "74901": "Environmental consulting activities",
  "74902": "Quantity surveying activities",
  "74909":
    "Other professional, scientific and technical activities not elsewhere classified",
  "74990": "Non-trading company",
  "75000": "Veterinary activities",
  "77110": "Renting and leasing of cars and light motor vehicles",
  "77120": "Renting and leasing of trucks and other heavy vehicles",
  "77210": "Renting and leasing of recreational and sports goods",
  "77220": "Renting of video tapes and disks",
  "77291": "Renting and leasing of media entertainment equipment",
  "77299": "Renting and leasing of other personal and household goods",
  "77310": "Renting and leasing of agricultural machinery and equipment",
  "77320":
    "Renting and leasing of construction and civil engineering machinery and equipment",
  "77330":
    "Renting and leasing of office machinery and equipment (including computers)",
  "77341": "Renting and leasing of passenger water transport equipment",
  "77342": "Renting and leasing of freight water transport equipment",
  "77351": "Renting and leasing of air passenger transport equipment",
  "77352": "Renting and leasing of freight air transport equipment",
  "77390":
    "Renting and leasing of other machinery, equipment and tangible goods not elsewhere classified",
  "77400":
    "Leasing of intellectual property and similar products, except copyright works",
  "78101": "Motion picture, television and other theatrical casting activities",
  "78109": "Other activities of employment placement agencies",
  "78200": "Temporary employment agency activities",
  "78300":
    "Human resources provision and management of human resources functions",
  "79110": "Travel agency activities",
  "79120": "Tour operator activities",
  "79901": "Activities of tourist guides",
  "79909": "Other reservation service activities not elsewhere classified",
  "80100": "Private security activities",
  "80200": "Security systems service activities",
  "80300": "Investigation activities",
  "81100": "Combined facilities support activities",
  "81210": "General cleaning of buildings",
  "81221": "Window cleaning services",
  "81222": "Specialised cleaning services",
  "81223": "Furnace and chimney cleaning services",
  "81229": "Other building and industrial cleaning activities",
  "81291": "Disinfecting and exterminating services",
  "81299": "Other cleaning services",
  "81300": "Landscape service activities",
  "82110": "Combined office administrative service activities",
  "82190":
    "Photocopying, document preparation and other specialised office support activities",
  "82200": "Activities of call centres",
  "82301": "Activities of exhibition and fair organisers",
  "82302": "Activities of conference organisers",
  "82911": "Activities of collection agencies",
  "82912": "Activities of credit bureaus",
  "82920": "Packaging activities",
  "82990": "Other business support service activities not elsewhere classified",
  "84110": "General public administration activities",
  "84120":
    "Regulation of health care, education, cultural and other social services, not incl. social security",
  "84130":
    "Regulation of and contribution to more efficient operation of businesses",
  "84210": "Foreign affairs",
  "84220": "Defence activities",
  "84230": "Justice and judicial activities",
  "84240": "Public order and safety activities",
  "84250": "Fire service activities",
  "84300": "Compulsory social security activities",
  "85100": "Pre-primary education",
  "85200": "Primary education",
  "85310": "General secondary education",
  "85320": "Technical and vocational secondary education",
  "85410": "Post-secondary non-tertiary education",
  "85421": "First-degree level higher education",
  "85422": "Post-graduate level higher education",
  "85510": "Sports and recreation education",
  "85520": "Cultural education",
  "85530": "Driving school activities",
  "85590": "Other education not elsewhere classified",
  "85600": "Educational support services",
  "86101": "Hospital activities",
  "86102": "Medical nursing home activities",
  "86210": "General medical practice activities",
  "86220": "Specialists medical practice activities",
  "86230": "Dental practice activities",
  "86900": "Other human health activities",
  "87100": "Residential nursing care facilities",
  "87200":
    "Residential care activities for learning difficulties, mental health and substance abuse",
  "87300": "Residential care activities for the elderly and disabled",
  "87900": "Other residential care activities not elsewhere classified",
  "88100":
    "Social work activities without accommodation for the elderly and disabled",
  "88910": "Child day-care activities",
  "88990":
    "Other social work activities without accommodation not elsewhere classified",
  "90010": "Performing arts",
  "90020": "Support activities to performing arts",
  "90030": "Artistic creation",
  "90040": "Operation of arts facilities",
  "91011": "Library activities",
  "91012": "Archives activities",
  "91020": "Museums activities",
  "91030":
    "Operation of historical sites and buildings and similar visitor attractions",
  "91040": "Botanical and zoological gardens and nature reserves activities",
  "92000": "Gambling and betting activities",
  "93110": "Operation of sports facilities",
  "93120": "Activities of sport clubs",
  "93130": "Fitness facilities",
  "93191": "Activities of racehorse owners",
  "93199": "Other sports activities",
  "93210": "Activities of amusement parks and theme parks",
  "93290": "Other amusement and recreation activities not elsewhere classified",
  "94110": "Activities of business and employers membership organisations",
  "94120": "Activities of professional membership organisations",
  "94200": "Activities of trade unions",
  "94910": "Activities of religious organisations",
  "94920": "Activities of political organisations",
  "94990":
    "Activities of other membership organisations not elsewhere classified",
  "95110": "Repair of computers and peripheral equipment",
  "95120": "Repair of communication equipment",
  "95210": "Repair of consumer electronics",
  "95220": "Repair of household appliances and home and garden equipment",
  "95230": "Repair of footwear and leather goods",
  "95240": "Repair of furniture and home furnishings",
  "95250": "Repair of watches, clocks and jewellery",
  "95290": "Repair of personal and household goods not elsewhere classified",
  "96010": "Washing and (dry-)cleaning of textile and fur products",
  "96020": "Hairdressing and other beauty treatment",
  "96030": "Funeral and related activities",
  "96040": "Physical well-being activities",
  "96090": "Other service activities not elsewhere classified",
  "97000": "Activities of households as employers of domestic personnel",
  "98000": "Residents property management",
  "98100":
    "Undifferentiated goods-producing activities of private households for own use",
  "98200":
    "Undifferentiated service-producing activities of private households for own use",
  "99000": "Activities of extraterritorial organisations and bodies",
  "99999": "Dormant Company",
};
