import { Box, List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { getResource } from "../../data/request";
import { getDateDisplay } from "../../data/utilities";
import { PSC } from "../../types/PSC";
export const PSCPage = (props: { link: string }) => {
  const { link } = props;
  const [persons, setPersons] = useState<PSC[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const doc = await getResource(link);
      setPersons(doc.items);
    };

    fetchData();
  }, []);
  return (
    <Box>
      <List>
        {persons.map((person, index) => (
          <ListItem>
            <Box
              style={{
                background: "white",
                width: "100%",
                padding: 20,
              }}
            >
              <Box>
                <b>Name: </b>
                {person.name}
              </Box>
              <Box>
                {" "}
                <b>Nationality: </b>
                {person.nationality}
              </Box>
              <Box>
                <b>Date of Birth:</b> {getDateDisplay(person.date_of_birth)}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
