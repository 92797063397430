import { Box } from "@mui/material";
import { getValueDisplay } from "../../data/utilities";

export const CompanyStatus = (props: { status: string | undefined }) => {
  let backgroundColor = "grey";

  if (props.status === "active") {
    backgroundColor = "green";
  } else if (props.status === "liquidation") {
    backgroundColor = "red";
  } else if (props.status === "disolved") {
    backgroundColor = "yellow";
  }

  return (
    <Box
      style={{
        backgroundColor,
        width: 100,
        height: 20,
        borderRadius: 5,
        color: "white",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      {getValueDisplay(props.status)}
    </Box>
  );
};
