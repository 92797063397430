import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { searchAll, searchCompanies } from "../data/request";
import { SearchResultList } from "./Components/SearchResultList";
import SearchBox from "./Components/SearchBox";
import { useNavigate, useParams } from "react-router-dom";
import { DELTA_PRIMARY, PAPER_GREY } from "./colors";
import { BORDER_RADIUS } from "./dimens";
import { CircularProgress, Typography } from "@mui/material";

export const SearchResultPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const { searchText } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (searchText != null) {
        setLoading(true);
        const json = await searchAll(searchText);
        setSearchResults(json.items);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchText]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{
        backgroundColor: PAPER_GREY,
        borderRadius: BORDER_RADIUS,
      }}
    >
      <SearchBox />

      {loading ? (
        // Display the loading screen when the data is being fetched
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <SearchResultList data={searchResults} />
      )}
    </Box>
  );
};
