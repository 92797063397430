import { Box, CircularProgress, Grid, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCompany } from "../../data/request";
import { CompanyDetails } from "../../types/CompanyDetails";
import { BasicInfo } from "./BasicInfo";
import { Financials } from "./Financials";
import { PSCPage } from "./PSCPage";
import SearchBox from "../Components/SearchBox";
import { PAPER_GREY } from "../colors";
import OfficersTable from "./OfficersTable";
import FillingHistoryTable from "./FilingHistoryTable";
import { BORDER_RADIUS } from "../dimens";
import { Helmet } from "react-helmet";

export const CompanyDetailsPage = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("basicInfo");
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>();

  useEffect(() => {
    const fetchData = async () => {
      if (params.companyNo != undefined) {
        setLoading(true);
        const doc = await getCompany(params.companyNo);
        console.log(doc);
        setCompanyDetails(doc);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box style={{ backgroundColor: PAPER_GREY, borderRadius: BORDER_RADIUS }}>
      <SearchBox />
      <Helmet>
        <meta
          name="description"
          content={
            companyDetails?.company_name +
            " " +
            companyDetails?.registered_office_address.postal_code
          }
        />
        <title>
          {"Directory - " +
            companyDetails?.company_name +
            " - " +
            companyDetails?.company_number}
        </title>
      </Helmet>

      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Grid container direction="column" style={{ padding: 20 }} spacing={2}>
          <Grid
            direction="column"
            container
            style={{ padding: 20, overflow: "auto" }}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              style={{
                padding: 10,
                overflow: "auto",
              }}
            >
              {companyDetails !== undefined && (
                <Grid
                  item
                  xs={12}
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    padding: 10,
                    marginRight: 20,
                    backgroundColor: "white",
                  }}
                >
                  <Box
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      alignItems: "center",
                    }}
                  >
                    {companyDetails.company_name}
                  </Box>
                  <Box>{companyDetails.company_number}</Box>
                </Grid>
              )}
              <Tabs
                orientation="horizontal"
                value={tab}
                onChange={(event, value) => {
                  setTab(value);
                }}
                aria-label="wrapped label tabs example"
                variant="fullWidth"
              >
                <Tab value="basicInfo" label="Basic Information" wrapped />
                <Tab value="officers" label="Officers" />
                <Tab value="filingHistory" label="Filing History" />
                <Tab value="psc" label="Persons with Significant Control" />
              </Tabs>
            </Grid>
            <Paper style={parentStyle}>
              <Grid item sx={{}} xs={12}>
                {tab === "officers" && (
                  <OfficersTable companyNo={params.companyNo} />
                )}
                {tab === "basicInfo" && companyDetails && (
                  <BasicInfo companyDetails={companyDetails} />
                )}
                {tab === "financials" && <Financials />}
                {tab === "filingHistory" && companyDetails && (
                  <FillingHistoryTable
                    link={companyDetails.links.filing_history}
                  />
                )}
                {tab === "psc" &&
                  companyDetails &&
                  companyDetails.links.persons_with_significant_control !=
                    undefined && (
                    <PSCPage
                      link={
                        companyDetails.links.persons_with_significant_control
                      }
                    />
                  )}
              </Grid>{" "}
            </Paper>
          </Grid>{" "}
        </Grid>
      )}
    </Box>
  );
};

const parentStyle = {
  overflow: "auto", // Enable scrolling when child content is too large
  maxWith: "200px",
};
