import { Box, TextField } from "@mui/material";
import SearchBox from "./Components/SearchBox";
import { DELTA_PRIMARY, PAPER_GREY } from "./colors";
import { BORDER_RADIUS } from "./dimens";
import News from "./Components/News";

export const Home = () => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{
        height: "95%",
        marginBottom: 40,
        alignSelf: "center",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: BORDER_RADIUS,
        backgroundColor: PAPER_GREY,
      }}
    >
      <SearchBox />
      <h2
        style={{
          paddingTop: 60,
          paddingLeft: 20,
        }}
      >
        {" "}
        Who are we?{" "}
      </h2>
      <div
        style={{
          padding: 20,
          flexFlow: "row",
          color: "999999",
          fontSize: 18,
        }}
      >
        Company Directory is a business intelligence tool that gives easy access
        to information on UK based companies that have been filed with Companies
        House – the UK’s registrar of companies. The app, site and API is
        designed to allow for due diligence on companies you are\may do business
        with; lead generation and qualification and financial \ credit checking
        by exploring charges and mortgages. The product allows easy exploration
        of related companies, either by common officers or due to an
        owner\subsidiary relationship.
      </div>
      <News />
    </Box>
  );
};
