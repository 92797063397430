import { useEffect, useState } from "react";
import { Officer } from "../../types/Officer";
import * as Utilities from "../../data/utilities";
import {
  API_ENDPOINT,
  getPdfMetaResource,
  getResource,
  HEADERS,
} from "../../data/request";
import { File } from "../../types/File";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Label } from "@mui/icons-material";

export const FillingHistoryTable = (props: { link: string }) => {
  const { link } = props;
  const [files, setFiles] = useState<File[]>([]);

  const [data, setData] = useState(files ?? []);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState<any>("asc");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);

  const downloadPDF = async (file: any) => {
    try {
      console.log(file.links.document_metadata);
      console.log(file.links.self);

      const response = await getPdfMetaResource(file.links.document_metadata);
      console.log(response);

      fetch(API_ENDPOINT + response.links.document, {
        headers: HEADERS,
      }).then(async (res) => {
        const jsonData = await res.json();
        console.log("location: " + jsonData.location);
        window.open(jsonData.location);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const doc = await getResource(link);
      console.log(doc);
      setFiles(doc.items);
      setData(doc.items);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSort = (property: keyof File) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = [...data].sort((a, b) => {
      if (isAsc) {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });
    setData(sortedData);
  };

  const handleFilter = (value: any) => {
    setFilter(value);
    const filteredData = files?.filter((item) => {
      return (
        item.date.toLowerCase().includes(value.toLowerCase()) ||
        item.description?.toLowerCase().includes(value.toLowerCase())
      );
    });
    setData(filteredData);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div>
          {files.length > 10 && (
            <TextField
              label="Filter by date or description"
              value={filter}
              onChange={(e) => handleFilter(e.target.value)}
              fullWidth
            />
          )}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "date"}
                    direction={orderBy === "date" ? order : "asc"}
                    onClick={() => handleSort("date")}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "description"}
                    direction={orderBy === "description" ? order : "asc"}
                    onClick={() => handleSort("description")}
                  >
                    Description
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Download</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.transaction_id}>
                  <TableCell>{Utilities.getDateDisplay(item.date)}</TableCell>
                  <TableCell>
                    {Utilities.getValueDisplay(item.description)}
                  </TableCell>
                  <TableCell>
                    <button onClick={() => downloadPDF(item)}>
                      Download PDF
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default FillingHistoryTable;
