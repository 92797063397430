import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PublicIcon from "@mui/icons-material/Public";
import { Box, ListItemIcon, styled } from "@mui/material";
import { SearchResult } from "../../types/SearchResult";
import { CompanyStatus } from "./CompanyStatus";
import { getDateDisplay, getValueDisplay } from "../../data/utilities";
import { DELTA_PRIMARY } from "../colors";
import { Link } from "react-router-dom";

const StyledDiv = styled("div")({
  marginBottom: 10,
  "&:hover": {
    color: "rgba(0, 0, 0, 0.6)",
    border: "1px solid #ccc", // 1px grey border
  },
});

export const SearchResultItem = (props: { data: SearchResult }) => {
  return (
    <StyledDiv>
      <Link
        to={props.data.links.self}
        style={{
          textDecoration: "none",
          color: "black",
          transition: "color 0.3s", // Add a smooth transition effect
        }}
      >
        <ListItem
          key={props.data.company_number}
          style={{
            background: "white",
            textDecoration: "none", // Remove the underline
          }}
        >
          <ListItemIcon>
            {props.data.company_type === "plc" ? (
              <PublicIcon style={{ color: DELTA_PRIMARY }} />
            ) : (
              <BusinessCenterIcon style={{ color: DELTA_PRIMARY }} />
            )}
          </ListItemIcon>
          <Box style={{ padding: 2 }}>
            {/* <Box>{props.data.snippet}</Box> */}
            <div
              style={{
                marginBottom: 5,
                fontWeight: "bold",
              }}
            >
              {props.data.title}
            </div>

            <Box style={{ padding: 2 }}>{props.data.address_snippet}</Box>
            {props.data.company_status !== undefined && (
              <CompanyStatus status={props.data.company_status}></CompanyStatus>
            )}

            {props.data.appointment_count !== undefined &&
              "Appointments: " + props.data.appointment_count}

            {props.data.kind === "searchresults#company" ? (
              <Box style={{ padding: 2 }}>{props.data.description}</Box>
            ) : (
              <Box style={{ padding: 2 }}>
                {getDateDisplay(props.data.date_of_birth)}
              </Box>
            )}
          </Box>
        </ListItem>
      </Link>
    </StyledDiv>
  );
};
