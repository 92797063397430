import { useEffect, useState } from "react";
import { getCompanyOfficers } from "../../data/request";
import { Officer, OfficersSummary } from "../../types/Officer";
import * as Utilities from "../../data/utilities";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getValueDisplay } from "../../data/utilities";

export const OfficersTable = (props: { companyNo: string | undefined }) => {
  const { companyNo } = props;
  //   const [officersSummary, setOfficersSummary] = useState<OfficersSummary | undefined>(
  //     undefined
  //   );

  const [officers, setOfficers] = useState<Officer[]>([]);

  const [data, setData] = useState(officers ?? []);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState<any>("asc");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);

  const transform = (officer_array: any): any[] => {
    let elements: any[] = [];

    officer_array.forEach((item: Officer) => {
      item["status"] = "Resigned";

      if (item["resigned_on"] === undefined) {
        item["status"] = "Active";
      }

      if (item["occupation"] === undefined) {
        item["occupation"] = item["officer_role"];
      }

      item["occupation"] = Utilities.getValueDisplay(item["occupation"]);

      elements.push(item);
    });
    return elements;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (companyNo != undefined) {
        setLoading(true);
        const doc = await getCompanyOfficers(companyNo);
        setOfficers(doc.items);
        setData(transform(doc.items));
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (property: keyof Officer) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = [...data].sort((a, b) => {
      if (isAsc) {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });
    setData(sortedData);
  };

  const handleFilter = (value: any) => {
    setFilter(value);
    const filteredData = officers?.filter((item) => {
      return (
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.nationality?.toLowerCase().includes(value.toLowerCase())
      );
    });
    setData(filteredData);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div>
          {officers.length > 10 && (
            <TextField
              label="Filter by name or nationality"
              value={filter}
              onChange={(e) => handleFilter(e.target.value)}
              fullWidth
            />
          )}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "occupation"}
                    direction={orderBy === "occupation" ? order : "asc"}
                    onClick={() => handleSort("occupation")}
                  >
                    Occupation
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "officer_role"}
                    direction={orderBy === "officer_role" ? order : "asc"}
                    onClick={() => handleSort("officer_role")}
                  >
                    Officer Role
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "nationality"}
                    direction={orderBy === "nationality" ? order : "asc"}
                    onClick={() => handleSort("nationality")}
                  >
                    Nationality
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? order : "asc"}
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.links}>
                  <TableCell>
                    <Link to={item.links.officer.appointments}>
                      {Utilities.getValueDisplay(item.name)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {Utilities.getValueDisplay(item.occupation)}
                  </TableCell>
                  <TableCell>
                    {Utilities.getValueDisplay(item.officer_role)}
                  </TableCell>
                  <TableCell>
                    {Utilities.getValueDisplay(item.nationality)}
                  </TableCell>
                  <TableCell>
                    {Utilities.getValueDisplay(item.status)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default OfficersTable;
