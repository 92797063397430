import { Box, ListItemIcon } from "@mui/material";
import { Item } from "../../types/Item";
import { FaCalendar, FaMapMarkerAlt } from "react-icons/fa";

export const ItemCard = (props: { item: Item }) => {
  const { item } = props;

  let icon = null;

  if (item.icon === "address") {
    icon = <FaMapMarkerAlt size={25} />;
  } else if (item.icon === "date") {
    icon = <FaCalendar size={25} />;
  }

  return (
    <Box sx={styles.container}>
      {item.icon !== undefined && <Box> {icon} </Box>}
      <Box sx={styles.innerContainer}>
        <Box style={styles.title}>{item.title}</Box>
        <Box style={styles.subtitle}>{item.subtitle}</Box>
        <Box style={styles.description}>{item.description}</Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    padding: "5px",
    margin: "10px",
    width: "300px",
  },
  title: {
    fontFamily: "Arial, sans-serif",
    fontSize: "18px",
    fontWeight: "700",
    color: "black",
  },
  subtitle: {
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    color: "black",
    paddingTop: "10px",
  },
  description: {
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "italic",
    color: "black",
  },
  innerContainer: {
    marginLeft: "10px",
  },
};
