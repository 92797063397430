import { RowingTwoTone } from "@mui/icons-material";
import List from "@mui/material/List";
import { PAPER_GREY } from "../colors";
import { SearchResultItem } from "./SearchResultItem";
const dummyData = [
  {
    name: "Tesco",
    address: "Tesco House Shire Park, Kestrel Way, Welwyn Garden City, AL7 1GA",
    status: "active",
    assets: "£49.35b",
    companyNo: "00445790",
    liability: "£-33.69b",
    NetAssets: "15.66b",
  },
  {
    name: "Sainsbury",
    address:
      "Sainsbury House Shire Park, Kestrel Way, Welwyn Garden City, AL7 1GA",
    status: "active",
    assets: "£49.35b",
    companyNo: "00445790",
    liability: "£-33.69b",
    NetAssets: "15.66b",
  },
  {
    name: "M & S",
    address: "M & S House Shire Park, Kestrel Way, Welwyn Garden City, AL7 1GA",
    status: "active",
    assets: "£49.35b",
    companyNo: "00445790",
    liability: "£-33.69b",
    NetAssets: "15.66b",
  },
];

export const SearchResultList = (props: { data: any[] }) => (
  <List sx={styles.container} style={{ background: PAPER_GREY }}>
    {props.data.map((value) => (
      <SearchResultItem data={value} />
    ))}
  </List>
);

const styles = {
  container: {
    flexDirection: "column",
    display: "flex",
    backgroundColor: PAPER_GREY,
    padding: "20px",
    margin: "10px",
  },
};
