export const PAPER_GREY = "rgba(240, 240, 240)";
export const BLUE = 'blue';
export const GREEN = 'green';
export const DELTA_PRIMARY = "#009999"


// export const colors = {
//     darkGrey: "#666666",
//     grey: "#cccccc",
//     lightGrey: "#efefefff",
//     green: "#6aa84fff",
//     yellow: "#f1c232ff",
//     red: "#a61c00ff",
//     blue: "#2874A6",
//     // turquoise
//     deltagain: "#009999",
//     deltagainLight: "#00b3b3"
//     // red
//     //deltagain: "#a61c00",
//     //deltagainLight: "#c83e3e"
//   }