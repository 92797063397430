import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getResource } from "../../data/request";
import { OfficerDetails } from "../../types/OfficerDetails";
import * as Utilities from "../../data/utilities";
import { AppointmentList } from "./AppointmentList";
import SearchBox from "../Components/SearchBox";
import { PAPER_GREY } from "../colors";
import { BORDER_RADIUS } from "../dimens";
import { Helmet } from "react-helmet";
import { getDateDisplay } from "../../data/utilities";

export const OfficerDetailsPage = () => {
  const params = useParams();

  const [officersDetails, setOfficerDetails] = useState<OfficerDetails>();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (params.resourceId != undefined) {
        setLoading(true);
        const doc = await getResource(
          "/officers/" + params.resourceId + "/appointments/"
        );
        console.log(doc);
        setOfficerDetails(translate(doc));
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const translate = (json: any) => {
    let nationality = "Unknown";
    let country_of_residence = "Unknown";
    let addresses: any = [];

    json["items"].forEach(function (item: any, index: any) {
      if (item["nationality"] != undefined) {
        nationality = item["nationality"];
      }

      if (item["country_of_residence"] != undefined) {
        country_of_residence = item["country_of_residence"];
      }

      addresses.push(Utilities.getAddressDispay(item["address"]));
    });

    let addresses_unique = Array.from(new Set(addresses));
    let known_addresses = addresses_unique.join("\n\n");
    let address_title = "Address";
    if (known_addresses.length > 1) {
      address_title = "Addresses";
    }

    let dob = "Unknown";
    if (json["date_of_birth"] != undefined) {
      dob =
        Utilities.MONTHS[json["date_of_birth"]["month"] - 1] +
        " " +
        json["date_of_birth"]["year"];
    }

    json["my_nationality"] = nationality;
    json["my_country_of_residence"] = country_of_residence;
    json["my_addresses"] = addresses;
    json["my_dob"] = dob;
    return json;
  };

  return (
    <Box style={{ background: PAPER_GREY, borderRadius: BORDER_RADIUS }}>
      <SearchBox />
      <Helmet>
        <meta
          name="description"
          content={
            officersDetails?.name +
            "address: " +
            officersDetails?.address +
            "nationality" +
            "Date of birth" +
            getDateDisplay(officersDetails?.date_of_birth)
          }
        />

        <title>{"Directory - " + officersDetails?.name}</title>
      </Helmet>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Grid style={{ background: "white", margin: 20 }}>
          {officersDetails !== undefined && (
            <Grid
              item
              xs={12}
              style={{
                margin: 20,
                padding: 10,
                width: "60%",
              }}
            >
              <Box>
                <Box style={{ fontSize: 20, fontWeight: "bold" }}>
                  {officersDetails.name}
                </Box>
                <Box>{"Date of Birth: " + officersDetails.my_dob}</Box>
                <Box>{"Address: " + officersDetails.my_addresses}</Box>
                <Box>{"Nationality: " + officersDetails.my_nationality}</Box>
                <Box>
                  {"Country of Residence: " +
                    officersDetails.my_country_of_residence}
                </Box>
              </Box>

              <AppointmentList data={officersDetails.items}></AppointmentList>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};
