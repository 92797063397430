import ListItem from "@mui/material/ListItem";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PublicIcon from "@mui/icons-material/Public";
import { Box, ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";
import { DELTA_PRIMARY } from "../colors";
import * as Utilities from "../../data/utilities";

export const AppointmentItem = (props: { data: any }) => {
  return (
    <ListItem key={props.data} disableGutters>
      <ListItemIcon>
        {props.data.company_type === "plc" ? (
          <PublicIcon />
        ) : (
          <BusinessCenterIcon />
        )}
      </ListItemIcon>
      <Box
        style={{
          flex: 1,
        }}
      >
        <Link to={"/company/" + props.data.appointed_to.company_number}>
          <Box style={{ fontWeight: "bold" }}>
            {props.data.appointed_to.company_name +
              "(" +
              props.data.appointed_to.company_number +
              ")"}
          </Box>
        </Link>
        {props.data.resigned_on == undefined && (
          <Box
            style={{
              marginTop: 4,
              marginBottom: 4,
              width: 100,
              height: 20,
              color: "white",
              backgroundColor: "green",
              textAlign: "center",
              borderRadius: 4,
            }}
          >
            Active
          </Box>
        )}
        {props.data.resigned_on != undefined && (
          <Box
            style={{
              color: "white",
              backgroundColor: "gray",
              width: 100,
              height: 20,
            }}
          >
            Resigned
          </Box>
        )}
        <Box>{props.data.occupation}</Box>
        <Box>
          Appointed On: {Utilities.getDateDisplay(props.data.appointed_on)}
        </Box>
        {props.data.resigned_on != undefined && (
          <Box>Resigned On: {props.data.resigned_on}</Box>
        )}
      </Box>
    </ListItem>
  );
};
