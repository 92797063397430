import { Box } from "@mui/material";
import {
  getAddressDispay,
  getDateDisplay,
  getValueDisplay,
} from "../../data/utilities";
import { CompanyDetails } from "../../types/CompanyDetails";
import { ItemCard } from "../Components/ItemCard";

export const BasicInfo = (props: { companyDetails: CompanyDetails }) => {
  const { companyDetails } = props;

  const items = [];
  items.push({
    title: "Date of Creation",
    subtitle: getDateDisplay(companyDetails.date_of_creation),
    icon: "date",
  });
  items.push({
    title: "Has been liquidated:",
    subtitle: getValueDisplay(companyDetails.has_been_liquidated),
    icon: "date",
  });
  items.push({
    title: "Has Insolvency History:",
    subtitle: getValueDisplay(companyDetails.has_insolvency_history),
    icon: "date",
  });
  items.push({
    title: "Jurisdiction",
    subtitle: getValueDisplay(companyDetails.jurisdiction),
    icon: "date",
  });
  items.push({
    title: "Registered Office",
    subtitle: getAddressDispay(companyDetails.registered_office_address),
    icon: "address",
  });

  return (
    <Box sx={styles.containerStyle}>
      {items.map((item) => (
        <ItemCard item={item} />
      ))}
    </Box>

    //   <Box>
    //     Address Line 1:{" "}
    //     {companyDetails.registered_office_address.address_line_1}
    //   </Box>
    //   <Box>
    //     Address Line 2:{" "}
    //     {companyDetails.registered_office_address.address_line_2}
    //   </Box>
    //   <Box> Locality: {companyDetails.registered_office_address.locality} </Box>
    //   <Box> Country: {companyDetails.registered_office_address.country}</Box>
    //   <Box>
    //     Postal Code: {companyDetails.registered_office_address.postal_code}
    //   </Box>
    // </Box>
  );
};

const styles = {
  containerStyle: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
  },
};
