import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { Box, Button, Container, Drawer, TextField } from "@mui/material";
import { SideDrawerList } from "./ui/Components/SideDrawerList";
import { Home } from "./ui/Home";
import { CompanyDetailsPage } from "./ui/CompanyDetailsPanels/CompanyDetailsPage";
import { About } from "./ui/About";
import backgroundImage from "./london.jpg";
import { OfficerDetailsPage } from "./ui/OfficerDetailsPanels/OfficerDetailsPage";
import { SearchResultPage } from "./ui/SearchResultPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DELTA_PRIMARY } from "./ui/colors";

// import React from 'react';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import Button from '@mui/material/Button';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: DELTA_PRIMARY, // Set your desired primary color
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div
          style={{
            background: `url(${backgroundImage}) center center fixed`,
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "flex-start",
            minHeight: "100vh",
            paddingTop: "30px",
          }}
        >
          {/* <Drawer
          style={{ width: "240px" }}
          anchor="left"
          open={true}
          BackdropProps={{ invisible: true }}
          variant="permanent"
        >
          <SideDrawerList />
        </Drawer> */}
          <Routes>
            <Route
              path="/"
              element={
                <Container>
                  <Home />
                </Container>
              }
            ></Route>
            <Route path="/search">
              <Route
                path=":searchText"
                element={
                  <Container>
                    <SearchResultPage />
                  </Container>
                }
              />
            </Route>
            <Route
              path="/about"
              element={
                <Container>
                  <About></About>
                </Container>
              }
            ></Route>
            <Route path="/company">
              <Route
                path=":companyNo"
                element={
                  <Container>
                    <CompanyDetailsPage />
                  </Container>
                }
              />
            </Route>
            <Route
              path="/officers/:resourceId/:details"
              element={
                <Container>
                  <OfficerDetailsPage />
                </Container>
              }
            />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
